import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { kdaColorStyle, winRatecolorRange } from "@/app/util.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  ChampionIcon,
  LeaguePoints,
  RoleIconWrapper,
  WinRateInfo,
} from "@/game-lol/components/ProfileChampionStats.style.jsx";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { computeChampionStats } from "@/game-lol/utils/compute-champion-stats.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import useSummonerRanks from "@/game-lol/utils/use-summoner-ranks.mjs";
import {
  getARAMStats,
  getDerivedId,
  getDerivedQueue,
  getStaticData,
  isARAMQueue,
} from "@/game-lol/utils/util.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ProfileChampionsStats({ name, region, queue }) {
  const { t } = useTranslation();
  const {
    lol: { playerChampionStats },
  } = useSnapshot(readState);
  const champions = getStaticData("champions");
  const derivedId = name && getDerivedId(region, name);

  const championsStats =
    playerChampionStats?.[getDerivedQueue(derivedId, queue)];

  const playerRanks = useSummonerRanks(derivedId);
  const aramData =
    playerChampionStats?.[getDerivedQueue(derivedId, QUEUE_SYMBOLS.aram)];
  const aramStats = getARAMStats(playerRanks, aramData);

  const isLoaded = championsStats;

  if (!isLoaded || championsStats instanceof Error) {
    return <Card loading style={{ height: 365 }} />;
  }

  const computedStats = computeChampionStats(championsStats);

  if (!champions || !computedStats.length || aramStats.isARAMPlayer)
    return null;

  return (
    <ListItemsTruncated
      title={t("lol:championPerformance", "Champion Performance")}
      list={computeChampionStats(championsStats)}
      onShowMore={() => {
        sendInteractionEvent("profile-champions-show-more");
      }}
      itemLeftContent={(item) => {
        const { basicStats, role } = item;
        const { kills = 0, deaths = 0, assists = 0 } = basicStats;
        const kda = (kills + assists) / (deaths || 1);
        const champKey = champions?.keys?.[item.championId];
        const champName = champions?.[champKey]?.name;
        const isARAM = isARAMQueue(role);
        const RoleIcon = getRoleIcon(role);

        return (
          <>
            <ChampionIcon>
              <ChampionImg size={40} championId={item.championId} />
              {!isARAM && (
                <RoleIconWrapper className="role-icon">
                  <RoleIcon />
                </RoleIconWrapper>
              )}
            </ChampionIcon>
            <div>
              <p className="type-callout--bold">{champName}</p>
              <p
                className="type-caption--semi"
                style={{ color: kdaColorStyle(kda) }}
              >
                {t("lol:matchHistory.kda", "{{kda}} KDA", {
                  kda: kda.toLocaleString(getLocale(), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                })}
              </p>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const { basicStats, gameCount } = item;
        const { wins = 0, lp } = basicStats;
        const winrate = wins / (gameCount || 1);
        const isARAM = isARAMQueue(item.queue);
        const lpColor = lp > 0 ? "var(--perf-pos3)" : "var(--perf-neg3)";

        return (
          <div>
            {!isARAM && lp >= 0 ? (
              lp !== undefined ? (
                <LeaguePoints>
                  <p className="type-caption--bold" style={{ color: lpColor }}>
                    {lp > 0 ? "+" : ""}
                    {t("lol:leaguePoints", "{{points}} LP", {
                      points: lp !== null && lp !== undefined ? lp : "-",
                    })}
                  </p>
                </LeaguePoints>
              ) : (
                <p className="type-caption">
                  {t("lol:countGame", "{{count}} Game", { count: gameCount })}
                </p>
              )
            ) : null}
            <WinRateInfo>
              <p
                className="type-caption--bold"
                style={{
                  color: winRatecolorRange(winrate * 100),
                }}
              >
                {winrate.toLocaleString(getLocale(), {
                  style: "percent",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
              &nbsp;&nbsp;
              <p className="type-caption" style={{ color: "var(--shade2)" }}>
                {t(
                  "lol:matchHistory.winsAndLossesWithHypen",
                  "{{wins}}W-{{losses}}L",
                  {
                    wins: wins ?? 0,
                    losses: (gameCount ?? 0) - (wins ?? 0),
                  },
                )}
              </p>
            </WinRateInfo>
          </div>
        );
      }}
      itemLinkFormat={(champ) => {
        const champKey = champions?.keys?.[champ.championId];
        if (!champKey) return null;
        return `/lol/profile/${region}/${name}/champions/${champ.role}/${champ.championId}`;
      }}
    />
  );
}

export default memo(ProfileChampionsStats);
