import React, { memo } from "react";
import { css } from "goober";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import getLoLMatches from "@/game-lol/utils/get-matchlist.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import {
  extractNameFromDerivedId,
  getDerivedId,
  isRemakeGame,
  separateDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import LoseStreak from "@/inline-assets/streak-lose.svg";
import WinStreak from "@/inline-assets/streak-win.svg";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const tagCSSOverwrite = () => css`
  .icon-left {
    margin-left: unset;
  }
`;

const LoLBadge = ({ region, name }) => {
  const state = useSnapshot(readState);
  const derivedId = getDerivedId(region, name);
  const derivedRiotId = extractNameFromDerivedId(derivedId);
  let gameName, tagLine;
  // this is required because deprecate profile routes that initially
  // don't have a Riot ID cause this to throw
  try {
    [gameName, tagLine] = separateDerivedRiotID(derivedRiotId);
  } catch (_) {
    return null;
  }

  const lolLocalMatchlists = state.lol.localMatchlists;
  const lolMatches = state.lol.matches;
  const lolMatchlists = state.lol.matchlists;
  const lolProfiles = state.lol.profiles;

  if (!lolLocalMatchlists || !lolMatches || !lolMatchlists) return null;

  const matches = getLoLMatches({
    derivedId,
    displayName: name,
    region,
    profiles: lolProfiles,
    localMatchlists: lolLocalMatchlists,
    matches: lolMatches,
    matchlists: lolMatchlists,
  });

  if (!matches.length) return null;

  const ordered = orderArrayBy(matches, (m) => m.match?.gameCreation, "desc");

  let type = null;
  let count = 0;

  for (const m of ordered) {
    const { match } = m;
    const { gameDuration, participants, queueId } = match;
    const isRemake = isRemakeGame(gameDuration);
    const isSoloDuoQueue = QueueSymbol(queueId) === QUEUE_SYMBOLS.rankedSoloDuo;
    if (!gameDuration || isRemake || !participants || !isSoloDuoQueue) continue;
    const me = participants.find(
      (p) => p?.riotIdGameName === gameName && p?.riotIdTagline === tagLine,
    );
    if (!me) continue;
    const win = me.win;
    if (!type) type = win ? "win" : "loss";
    if ((type === "win" && !win) || (type === "loss" && win)) break;
    count++;
  }

  if (!count) return null;

  return (
    <Tag
      className={tagCSSOverwrite()}
      size="sm"
      text={count}
      iconLeft={type === "win" ? <WinStreak fill="#FF9417" /> : <LoseStreak />}
      color={type === "win" ? "#ff9417" : "#49B4FF"}
    />
  );
};

const LoLWinStreakBadge = memo(LoLBadge);

export { LoLWinStreakBadge };
