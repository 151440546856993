import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import { isAbandonedMatch, isMatchInQueue } from "@/game-lol/utils/util.mjs";
import unionBy from "@/util/union-by.mjs";

export default function getMatchlist({
  localMatchlists,
  matchlists,
  matches,
  region,
  queue,
  displayName,
  derivedId,
  profiles,
}) {
  const localMatchList = localMatchlists?.[derivedId] || [];
  const matchList = matchlists?.[derivedId];

  const sanitizedMatchList =
    matchList && !(matchList instanceof Error) ? matchList : [];
  const mergedMatchlist = unionBy(localMatchList, sanitizedMatchList, "id");

  const filteredMatchlist = mergedMatchlist.reduce((acc, matchlistItem) => {
    const match = matches[matchlistItem.id];
    if (match && isMatchInQueue(match, queue) && !isAbandonedMatch(match)) {
      const gameCreation = match?.gameCreation;
      const result = {
        ...matchlistItem,
        match,
        derivedId,
        region,
        displayName,
        profile: profiles[derivedId],
        gameSymbol: GAME_SYMBOL_LOL,
      };
      if (gameCreation) result.createdAt = new Date(gameCreation);
      acc.push(result);
    }
    return acc;
  }, []);

  filteredMatchlist.sort((a, b) => {
    const { match: matchA } = a;
    const { match: matchB } = b;

    if (matchA && matchB) {
      const { gameCreation: creationA } = matchA;
      const { gameCreation: creationB } = matchB;

      return creationB - creationA;
    }
    return 0;
  });

  return filteredMatchlist;
}
